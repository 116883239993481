@font-face {
  font-family: "Barlow Medium";
  src: url(../public/fonts/Barlow-Medium.woff)
}
@font-face {
  font-family: "Barlow Semibold";
  src: url(../public/fonts/Barlow-SemiBold.woff);
}
@font-face {
  font-family: "Barlow";
  src: url(../public/fonts/Barlow-Regular.woff);
}

:root {
  --doc-height: 100%;
 }