.map-wrap {
  position: relative;
  width: 100%;
  height: 100vh;
  height: var(--doc-height);

  .map {
    position: absolute;
    width: 100%;
    height: 100%;
  }
}
