/*.filter-group {
    font: 12px/20px 'Helvetica Neue', Arial, Helvetica, sans-serif;
    font-weight: 600;
    position: absolute;
    top: 10px;
    right: 10px;
    z-index: 1;
    border-radius: 3px;
    width: 120px;
    color: #fff;
    }
     
    .filter-group input[type='checkbox']:first-child + label {
    border-radius: 3px 3px 0 0;
    }
     
    .filter-group label:last-child {
    border-radius: 0 0 3px 3px;
    border: none;
    }
     
    .filter-group input[type='checkbox'] {
    display: none;
    }
     
    .filter-group input[type='checkbox'] + label {
    background-color: #3386c0;
    display: block;
    cursor: pointer;
    padding: 10px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.25);
    }
     
    .filter-group input[type='checkbox'] + label {
    background-color: #3386c0;
    text-transform: capitalize;
    }
     
    .filter-group input[type='checkbox'] + label:hover,
    .filter-group input[type='checkbox']:checked + label {
    background-color: #4ea0da;
    }
     
    .filter-group input[type='checkbox']:checked + label:before {
    content: '✔';
    margin-right: 5px;
    }

*/
// new

.nav-filter {
  background-color: #303030;
  border-radius: 5px;
  color: hsla(0, 0%, 100%, 0.6);
  position: absolute;
  top: 15px;
  right: 15px;
  z-index: 1000;

  &__items {
    padding: 20px;
    padding-top: 0;
  }

  input[type="checkbox"]+label {
    display: block;
    cursor: pointer;
    font-size: 16px;
    line-height: 2.5;
    font-family: "Barlow", sans-serif;
    font-weight: 400;
    border-bottom: 1px solid hsla(0, 0%, 100%, 0.3);
    width: 200px;
  }

  input[type="checkbox"] {
    display: none;
  }

  input[type="checkbox"]+label {
    display: flex;
    justify-content: space-between;

    .unchecked-label {
      display: inline-block;
    }

    .checked-label {
      display: none;
    }
  }

  input[type="checkbox"]:checked+label {
    color: #ffffff;

    .unchecked-label {
      display: none;
    }

    .checked-label {
      display: inline-block;
    }
  }
}

.hamburger-wrapper {
  background-color: #303030;
  position: absolute;
  z-index: 1000;
  right: 10px;
  top: 10px;
  border-radius: 5px;
  overflow: hidden;
}

label.hamburg {
  display: block;
  width: 50px;
  height: 50px;
  position: relative;
  margin-left: auto;
  cursor: pointer;
}

input#hamburg {
  display: none
}

.line {
  position: absolute;
  left: 10px;
  height: 1px;
  width: 30px;
  background: #ffffff;
  display: block;
  transition: 0.2s;
  transform-origin: center;
}

.line:nth-child(1) {
  top: 18px;
}

.line:nth-child(2) {
  top: 30px;
}

#hamburg:checked+.hamburg .line:nth-child(1) {
  transform: translateY(6px) rotate(-45deg);
}

#hamburg:checked+.hamburg .line:nth-child(2) {
  transform: translateY(-6px) rotate(45deg);
}

label.hamburg {
  border-bottom: none !important;
  width: 50px !important;
}