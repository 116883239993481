a {
    color: unset;
    text-decoration: unset;
    cursor: pointer;
}

#pop-up {
    z-index: 1000;
    position: absolute;
    bottom: 30px;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;

}

.pop-up {
    &-content {
        display: flex;
        background-color: #303030;
        color: #ffffff;
        border-radius: 2px;
        overflow: hidden;
        box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px;
    }

    &__link-arrow {
        position: absolute;
        right: 25px;
        bottom: 25px;
        transition: transform ease-in-out 0.2s;
    }

    &__text {
        padding: 20px;
        width: 300px;
        position: relative;

        @media (max-width: 599px) {
            width: 160px;
        }
    }

    &__link {
        color: unset;
        text-decoration: unset;
        cursor: pointer;
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
        padding: 20px;

        &:hover {
            .pop-up__link-arrow {
                transform: translateX(5px);

            }
        }
    }

    &__tag {
        color: #7f95bf;
        text-transform: uppercase;
        font-size: 12px;
        letter-spacing: 2px;
        line-height: 1.625;
        font-weight: 500;
        font-family: "Barlow Medium";
        margin-bottom: 5px;
    }

    &__title {
        font-size: 21px;
        font-weight: 600;
        line-height: 1.2;
        font-family: "Barlow SemiBold";
    }

    &__excerpt {
        font-size: 12px;
        color: #858585;
        line-height: 1.5;

    }

    &__image {
        width: 150px;
        height: 150px;

        img {
            display: block;
        }
    }
}

.close-icon {
    position: absolute;
    top: 0;
    right: 0;
    padding: 10px;
    z-index: 1002;
}